<template>
  <div style="height:100%;padding:1px;">
    <base-info v-if="basePage" :formState="formState"></base-info>
    <update-info
      v-else
      :oldFormState="formState"
      @changeBasePage="changeBasePage"
      :upDateForm="upDateForm"
    ></update-info>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import baseInfo from "./components/BaseInfo";
import updateInfo from "./components/UpdateInfo";
import { useQueryCompanyDetailApi } from "@/apis/account";
import { state } from "@/store/modules/user";

export default defineComponent({
  setup() {
    const QueryCompanyDetail = useQueryCompanyDetailApi();
    const basePage = ref(true);
    const formState = ref({
      name: state.companyName, //企业名称
      tax_number: state.taxNumber, //税号
      buyer_bank: "", //开户银行
      buyer_bank_account: "", //银行账号
      address: [], //企业地址
      province: "", //省
      city: "", //市
      area: "", //区
      buyer_address: "", //详细地址
      legal_name: "", //法人姓名
      legal_id_no: "", //身份证号
      contact_phone: "", //单位电话
      industrial_park: undefined, //归属产业园
      emergency_contact_phone: "", //手机号
      business_license: {}, //营业执照
      business_license_arr: [], //营业执照
      id_card_head: {}, //身份证正面
      id_card_head_arr: [], //身份证正面
      id_card_national_emblem: {}, //身份证反面
      id_card_national_emblem_arr: [] //身份证反面
    });

    //查询企业信息
    const queryCompanyDetail = async () => {
      const res = await QueryCompanyDetail({
        company_id: state.companyId
      });
      Object.keys(res).forEach(v => {
        if (res[v]) {
          formState.value[v] = res[v];
        }
      });
      console.log("formState2===>", formState);
      formState.value.business_license_arr = res.business_license
        ? [res.business_license]
        : []; //营业执照arr
      formState.value.id_card_head_arr = res.id_card_head
        ? [res.id_card_head]
        : []; //身份证正面arr
      formState.value.id_card_national_emblem_arr = res.id_card_national_emblem
        ? [res.id_card_national_emblem]
        : []; //身份证反面arr
    };

    queryCompanyDetail();
    //更新本页数据
    const upDateForm = res => {
      Object.keys(res).forEach(v => {
        formState.value[v] = res[v];
      });
    };
    const changeBasePage = val => {
      basePage.value = val;
    };
    return {
      basePage,
      formState,
      changeBasePage,
      upDateForm
    };
  },
  components: { baseInfo, updateInfo }
});
</script>

<template>
  <div
    class="ma-24 bg-white pt-24 pb-24"
    style="box-sizing:border-box;overflow:auto;min-height:100%;min-height:calc(100% - 48px);"
  >
    <a-form
      layout="inline"
      style="width:850px;margin:0 auto;"
      :label-col="{ style: 'width:110px' }"
      :wrapper-col="{ style: 'width:290px' }"
      :model="formState"
    >
      <a-form-item label="企业名称">
        {{ formState.name || "-" }}
      </a-form-item>
      <a-form-item disabled label="企业税号">
        {{ formState.tax_number || "-" }}
      </a-form-item>
      <a-form-item label="开户银行">
        {{ formState.buyer_bank || "-" }}
      </a-form-item>
      <a-form-item label="银行账号">
        {{ formState.buyer_bank_account || "-" }}
      </a-form-item>
      <a-form-item label="企业注册地址">
        {{ formState.buyer_address || "-" }}
      </a-form-item>
      <a-form-item disabled label="法人姓名">
        {{ formState.legal_name || "-" }}
      </a-form-item>
      <a-form-item disabled label="法人身份证号">
        {{ formState.legal_id_no || "-" }}
      </a-form-item>
      <a-form-item label="单位电话">
        {{ formState.contact_phone || "-" }}
      </a-form-item>
      <a-form-item label="入驻产业园">
        {{ enumDictMap[formState.industrial_park] || "-" }}
      </a-form-item>
      <a-form-item label="手机号">
        {{ formState.emergency_contact_phone || "-" }}
      </a-form-item>
      <a-form-item
        disabled
        label="营业执照"
        style="width:100%;"
        class="mt-10"
        :wrapper-col="{ style: 'width:calc(100% - 110px)' }"
      >
        <img class="companyImg" :src="business_license" alt="" />
      </a-form-item>
      <a-form-item
        disabled
        label="法人身份证照片"
        style="width:100%;"
        class="mt-10"
        :wrapper-col="{ style: 'width:calc(100% - 110px)' }"
      >
        <img class="companyImg mr-10" :src="id_card_head" alt="" />
        <img class="companyImg" :src="id_card_national_emblem" alt="" />
      </a-form-item>

      <a-form-item
        class="mt-30"
        style="width:100%;text-align:center;"
        :wrapper-col="{ style: 'width:100%;' }"
      >
        <a-button
          v-if="$store.getters.roles.includes('EnterpriseUpdate')"
          class="ml-24"
          type="primary"
          @click="$parent.basePage = false"
        >
          修改
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, computed, ref, watch } from "vue";
import { useEnumDictListApi } from "@/apis/account";
export default defineComponent({
  props: {
    formState: Object
  },
  setup(props) {
    //获取产业园数据字典//生成map表
    const enumDictMap = ref({});
    const enumDictList = useEnumDictListApi();
    enumDictList(["INDUSTRIAL_PARK"]).then(res => {
      let data = res[0].dict_info_responses;
      if (data) {
        data.forEach(item => {
          enumDictMap.value[item.code] = item.name;
        });
      }
    });

    // const computedAddress = computed(() => {
    //   if (
    //     props.formState.province &&
    //     props.formState.city &&
    //     props.formState.area
    //   ) {
    //     return `${props.formState.province}/${props.formState.city}/${props.formState.area}`;
    //   } else {
    //     return "-";
    //   }
    // });
    const computedAddress = ref();
    watch(
      [props.formState],
      () => {
        console.log(
          !!(
            props.formState.province &&
            props.formState.city &&
            props.formState.area
          )
        );

        if (
          props.formState.province &&
          props.formState.city &&
          props.formState.area
        ) {
          computedAddress.value = `${props.formState.province}/${props.formState.city}/${props.formState.area}`;
        } else {
          computedAddress.value = "-";
        }
      },
      { immediate: true }
    );
    let conputedImg = img => {
      const imgBase = {
        business_license:
          "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/business-license.png",
        id_card_head:
          "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-font.png",
        id_card_national_emblem:
          "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-back.png"
      };
      return computed(() => {
        let originImg = props.formState[img];
        if (originImg && originImg.data) {
          return originImg.data;
        } else {
          return imgBase[img];
        }
      });
    };
    const business_license = conputedImg("business_license");
    const id_card_head = conputedImg("id_card_head");
    const id_card_national_emblem = conputedImg("id_card_national_emblem");

    // const computedBusinessLicense = computed(() => {
    //   return props.formState.address.join("/");
    // });
    // const computedIdCardHead = computed(() => {
    //   return props.formState.address.join("/");
    // });
    // const computedIdCardNationalEmblem = computed(() => {
    //   return
    // });
    return {
      computedAddress,
      business_license,
      id_card_head,
      id_card_national_emblem,
      enumDictMap
    };
  },
  components: {}
});
</script>
<style lang="less" scoped>
/* 表单样式调整 */
:deep(.ant-form-inline .ant-form-item) {
  margin-bottom: 0px;
}
:deep(.ant-input) {
  border-radius: 4px;
}
//取消冒号
:deep(.ant-form-item-label > label) {
  margin-right: 16px;
  &::after {
    display: none;
  }
}
.companyImg {
  width: 180px;
  height: 120px;
  border-radius: 4px;
  padding: 8px;
  background: #fbfcfd;
}
</style>

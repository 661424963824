<template>
  <div
    class="ma-24 bg-white pt-40 pb-40"
    style="box-sizing:border-box;overflow:auto;min-height:calc(100% - 128px);"
  >
    <a-form
      layout="inline"
      ref="formRef"
      :rules="rules"
      style="width:900px;margin:0 auto;"
      :label-col="{ style: 'width:130px' }"
      :wrapper-col="{ style: 'width:290px' }"
      :model="formState"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item label="企业名称" name="name">
        <a-input placeholder="请输入" v-model:value="formState.name" disabled />
      </a-form-item>
      <a-form-item label="企业税号" name="tax_number">
        <a-input
          placeholder="请输入"
          v-model:value="formState.tax_number"
          disabled
        />
      </a-form-item>
      <a-form-item label="开户银行" name="buyer_bank">
        <a-input placeholder="请输入" v-model:value="formState.buyer_bank" />
      </a-form-item>
      <a-form-item label="银行账号" name="buyer_bank_account">
        <a-input
          placeholder="请输入"
          v-model:value="formState.buyer_bank_account"
        />
      </a-form-item>
      <!-- <a-form-item label="企业地址信息" name="address">
        <p-cascader
          placeholder="请选择省/市/区"
          v-model:value="formState"
          @change="handleCityCheck"
          :disabled="disableData.address"
        />
      </a-form-item> -->
      <a-form-item label="企业注册地址" name="buyer_address">
        <a-input placeholder="请输入" v-model:value="formState.buyer_address" />
      </a-form-item>
      <a-form-item label="法人姓名" name="legal_name" required>
        <a-input placeholder="请输入" v-model:value="formState.legal_name" />
      </a-form-item>
      <a-form-item label="法人身份证号" name="legal_id_no" required>
        <a-input placeholder="请输入" v-model:value="formState.legal_id_no" />
      </a-form-item>
      <a-form-item label="单位电话" name="contact_phone">
        <a-input placeholder="请输入" v-model:value="formState.contact_phone" />
      </a-form-item>
      <a-form-item label="入驻产业园" name="industrial_park">
        <!-- v-model:value="formState.industrial_park" -->
        <a-select
          placeholder="请选择产业园"
          ref="select"
          v-model:value="formState.industrial_park"
        >
          <a-select-option
            v-for="item of enumDictData"
            :value="item.value"
            :key="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="手机号" name="emergency_contact_phone">
        <a-input
          placeholder="请输入"
          v-model:value="formState.emergency_contact_phone"
        />
      </a-form-item>
      <a-form-item
        label="营业执照"
        name="business_license_arr"
        style="width:100%;"
        :wrapper-col="{ style: 'width:calc(100% - 130px)' }"
        required
      >
        <div
          :class="{
            business_license: true,
            isUpload: formState.business_license_arr.length === 1
          }"
        >
          <template
            v-for="(item, index) in formState.business_license_arr"
            :key="index"
          >
            <p-uploader
              :value="item"
              @input="e => License.uploadInputHandle(e, index)"
              :size="{ w: '180px', h: '120px' }"
              class="mx-8 mb-12"
            />
          </template>
          <p-uploader-trigger
            v-show="formState.business_license_arr.length < 1"
            @selected="License.handleSelected"
            fileType="IDCARD_BACK"
            :fileSize="8"
            :size="{ w: '180px', h: '120px' }"
            class="mx-8 mb-12"
          />
          <span
            class="mb-26"
            style="display:inline-block;vertical-align:bottom;color:#c1c1c1;font-size:12px;"
            >仅支持jpg、png格式，大小限制为8M</span
          >
        </div>
      </a-form-item>
      <a-form-item
        label="法人身份证照片"
        style="width:100%;"
        :wrapper-col="{ style: 'width:calc(100% - 130px)' }"
        name="id_card_head"
        required
      >
        <div
          :class="{
            IDtop: true,
            isUpload: formState.id_card_head_arr.length === 1
          }"
        >
          <template
            v-for="(item, index) in formState.id_card_head_arr"
            :key="index"
          >
            <p-uploader
              :value="item"
              @input="e => Front.uploadInputHandle(e, index)"
              :size="{ w: '180px', h: '120px' }"
              class="mx-8 mb-12"
            />
          </template>
          <p-uploader-trigger
            v-show="formState.id_card_head_arr.length < 1"
            @selected="Front.handleSelected"
            :fileSize="8"
            :size="{ w: '180px', h: '120px' }"
            class="mx-8 mb-12"
          />
        </div>
        <div
          :class="{
            IDbtm: true,
            isUpload: formState.id_card_national_emblem_arr.length === 1
          }"
        >
          <template
            v-for="(item, index) in formState.id_card_national_emblem_arr"
            :key="index"
          >
            <p-uploader
              :value="item"
              @input="e => Back.uploadInputHandle(e, index)"
              :size="{ w: '180px', h: '120px' }"
              class="mx-8 mb-12"
            />
          </template>
          <p-uploader-trigger
            v-show="formState.id_card_national_emblem_arr.length < 1"
            @selected="Back.handleSelected"
            :fileSize="8"
            :size="{ w: '180px', h: '120px' }"
            class="mx-8 mb-12"
          />
          <span
            class="mb-26"
            style="display:inline-block;vertical-align:bottom;color:#c1c1c1;font-size:12px;"
            >仅支持jpg、png格式，大小限制为8M</span
          >
        </div>
      </a-form-item>

      <a-form-item
        style="width:100%;text-align:center;"
        :wrapper-col="{ style: 'width:100%;' }"
      >
        <a-button @click="resetForm">
          取消
        </a-button>
        <a-button class="ml-24" type="primary" html-type="submit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, ref, createVNode, watch, toRefs, toRaw } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal, message } from "ant-design-vue";
import { useBatchUpload } from "@/hooks/UseBatchUpload";
import { useUpdateCompanyDetailApi, useEnumDictListApi } from "@/apis/account";
import { state } from "@/store/modules/user";

export default defineComponent({
  props: {
    oldFormState: {
      type: Object,
      required: true
    },
    upDateForm: {
      type: Function,
      required: true
    }
  },
  setup(props, con) {
    //获取数据字典
    const enumDictData = ref();
    const enumDictList = useEnumDictListApi();
    enumDictList(["INDUSTRIAL_PARK"]).then(res => {
      enumDictData.value = res[0].dict_info_responses;
    });
    const formRef = ref();
    /* const QueryCompanyDetail = useQueryCompanyDetailApi(); */
    const UpdateCompanyDetail = useUpdateCompanyDetailApi();
    const basePage = ref(true);
    const formState = ref({
      name: state.companyName, //企业名称
      tax_number: state.taxNumber, //税号
      buyer_bank: "", //开户银行
      buyer_bank_account: "", //银行账号
      province: "", //省
      city: "", //市
      area: "", //区
      buyer_address: "", //详细地址
      legal_name: "", //法人姓名
      legal_id_no: "", //身份证号
      contact_phone: "", //单位电话
      industrial_park: undefined, //归属产业园
      emergency_contact_phone: "", //手机号
      business_license: {}, //营业执照
      business_license_arr: [], //营业执照
      id_card_head: {}, //身份证正面
      id_card_head_arr: [], //身份证正面
      id_card_national_emblem: {}, //身份证反面
      id_card_national_emblem_arr: [] //身份证反面
    });
    //将oldFormState赋值给formState
    if (props.oldFormState) {
      console.log("props.oldFormState===>", props.oldFormState);
      Object.keys(props.oldFormState).forEach(
        v => (formState.value[v] = props.oldFormState[v])
      );
    }

    const disableData = {
      legal_name: false,
      legal_id_no: false,
      business_license: false,
      id_card_head: false,
      buyer_bank: false,
      buyer_bank_account: false,
      address: false,
      buyer_address: false
    };
    const { id_card_national_emblem_arr } = toRefs(formState.value);
    //validateInfos及自定义验证
    //身份证上传验证规则
    let validateIdCardHead = () => {
      let watchIdCard;
      return async () => {
        if (!watchIdCard) {
          watchIdCard = watch(
            id_card_national_emblem_arr,
            (newValue, oldValue) => {
              let oldLen = oldValue.length;
              let newLen = newValue.length;
              if (oldLen === 0 && newLen === 1) {
                formRef.value.validate(["id_card_head"]);
              }
            }
          );
        }
        let idCardFrontArr = formState.value.id_card_head_arr;
        let idCardBackArr = formState.value.id_card_national_emblem_arr;
        if (idCardFrontArr.length === 0) {
          return Promise.reject("请上传身份证正面");
        } else if (idCardBackArr.length === 0) {
          return Promise.reject("请上传身份证反面");
        }
        return Promise.resolve();
      };
    };
    //企业地址验证规则
    let validateAddress = () => {
      if (
        formState.value.province &&
        formState.value.city &&
        formState.value.area
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject("企业信息不能为空");
      }
    };
    //银行卡验证规则
    const validateBuyerBankAccount = () => {
      if (!formState.value.buyer_bank_account) {
        return Promise.resolve();
      } else {
        let reg = /^\d{9,30}$/;
        if (reg.test(formState.value.buyer_bank_account)) {
          return Promise.resolve();
        } else {
          return Promise.reject("银行账号不正确");
        }
      }
    };
    //汉字验证规则[\u4e00-\u9fa5]
    const validateLegalName = () => {
      if (!formState.value.legal_name) {
        return Promise.reject("法人姓名不能为空");
      } else {
        let reg = /^[\u4e00-\u9fa5]{2,4}$/;
        if (reg.test(formState.value.legal_name)) {
          return Promise.resolve();
        } else {
          return Promise.reject("姓名不正确");
        }
      }
    };
    //身份证上传验证规则/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    const rules = {
      legal_name: [
        {
          required: true,
          validator: validateLegalName,
          trigger: "blur"
        }
      ],
      legal_id_no: [
        {
          required: true,
          message: "法人身份证号不正确",
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          trigger: "blur"
        }
      ],
      contact_phone: [
        {
          required: false,
          message: "单位电话不正确",
          pattern: /(^\d{3}-\d{8}|\d{4}-\d{7})|((13|14|15|17|18|19)[0-9]{9})/,
          trigger: "blur"
        }
      ],
      industrial_park: [
        {
          required: true,
          message: "入驻产业园不能为空",
          trigger: "blur"
        }
      ],
      emergency_contact_phone: [
        {
          required: false,
          message: "手机号码不正确",
          pattern: /(13|14|15|17|18|19)[0-9]{9}/,
          trigger: "blur"
        }
      ],
      address: [
        {
          required: true,
          validator: validateAddress
        }
      ],
      business_license_arr: [
        {
          required: true,
          message: "营业执照不能为空"
        }
      ],
      id_card_head: [
        {
          required: true,
          validator: validateIdCardHead()
        }
      ],
      buyer_bank_account: [
        {
          required: false,
          validator: validateBuyerBankAccount,
          trigger: "blur"
        }
      ]
    };
    // 图片上传
    //handleSelected, uploadInputHandle
    const License = useBatchUpload(formState, "business_license_arr");
    //营业执照
    const Front = useBatchUpload(formState, "id_card_head_arr");
    //IDcard
    const Back = useBatchUpload(formState, "id_card_national_emblem_arr");

    /* //查询企业信息
    const queryCompanyDetail = async () => {
      const res = await QueryCompanyDetail({
        company_id: state.companyId
      });
      Object.keys(res).forEach(v => {
        formState.value[v] = res[v];
      });
      formState.value.business_license_arr = res.business_license
        ? [res.business_license]
        : []; //营业执照arr
      formState.value.id_card_head_arr = res.id_card_head
        ? [res.id_card_head]
        : []; //身份证正面arr
      formState.value.id_card_national_emblem_arr = res.id_card_national_emblem
        ? [res.id_card_national_emblem]
        : []; //身份证反面arr
    };
    queryCompanyDetail(); */
    //禁用判断
    const oldRes = toRaw(props.oldFormState);

    disableData.legal_name = !!oldRes.legal_name;
    disableData.legal_id_no = !!oldRes.legal_id_no;
    disableData.business_license = !!oldRes.business_license;
    disableData.id_card_head = !!oldRes.id_card_head;
    disableData.buyer_bank = !!oldRes.buyer_bank;
    disableData.buyer_bank_account = !!oldRes.buyer_bank_account;
    disableData.address = !!oldRes.area;
    disableData.buyer_address = !!oldRes.buyer_address;
    disableData.contact_phone = !!oldRes.contact_phone;

    // window.disableData = disableData;
    // 省市区选择
    const handleCityCheck = value => {
      formState.value.province = value[0]; //省
      formState.value.city = value[1]; //市
      formState.value.area = value[2]; //区
    };
    const handleFinish = values => {
      console.log(values, formState);
      showConfirm();
    };
    const handleFinishFailed = errors => {
      console.log(errors);
    };
    const success = () => {
      message.success("提交成功！");
      props.upDateForm(formState.value);
      con.emit("changeBasePage", true);
    };

    const error = () => {
      message.error("提交失败！");
    };
    const onSubmit = () => {
      formState.value.company_id = state.companyId;
      formState.value.business_license =
        formState.value.business_license_arr[0] || {};
      formState.value.id_card_head = formState.value.id_card_head_arr[0] || {};
      formState.value.id_card_national_emblem =
        formState.value.id_card_national_emblem_arr[0] || {};
      UpdateCompanyDetail(formState.value).then(
        () => {
          success();
        },
        () => {
          error();
        }
      );
    };
    const showConfirm = () => {
      Modal.confirm({
        title: "是否确定提交当前填写内容",
        icon: createVNode(ExclamationCircleOutlined),
        content: "部分信息提交后不可修改，请确认无误后提交",
        onOk() {
          onSubmit();
        }
      });
    };
    const resetForm = () => {
      formRef.value.resetFields();
      con.emit("changeBasePage", true);
    };
    return {
      basePage,
      rules,
      resetForm,
      handleFinish,
      handleFinishFailed,
      License,
      Front,
      Back,
      handleCityCheck,
      disableData,
      showConfirm,
      formState,
      formRef,
      enumDictData
    };
  }
});
</script>
<style scoped lang="less">
/* 表单样式调整 */
:deep(.ant-form-inline .ant-form-item) {
  margin-bottom: 22px;
}
:deep(.ant-input) {
  border-radius: 4px;
}
//取消冒号
:deep(.ant-form-item-label > label) {
  margin-right: 16px;
  &::after {
    display: none;
  }
}
/* 上传样式调整 */
.IDtop,
.IDbtm {
  display: inline-block;
  vertical-align: top;
  line-height: 0;
}
:deep(.ant-upload.ant-upload-select-picture-card) {
  width: 100%;
  height: 100%;
}
// 图片宽高
:deep(.image-show) {
  max-height: 102px;
  max-width: 162px;
}
:deep(.ant-upload-text) {
  display: none;
}
.business_license {
  line-height: 0;
  & :deep(.ant-upload.ant-upload-select-picture-card) {
    background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/business_license_upload.png")
      center/cover;
    margin-bottom: 0;
  }
}
.IDtop :deep(.ant-upload.ant-upload-select-picture-card) {
  background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-front-upload.png")
    center/cover;
  margin-bottom: 0;
}
.IDbtm :deep(.ant-upload.ant-upload-select-picture-card) {
  background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-back-upload.png")
    center/cover;
  margin-bottom: 0;
}
:deep(.w-upload) {
  display: inline-block;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 16px !important;
}
.isUpload :deep(.ant-upload.ant-upload-select-picture-card > .ant-upload) {
  background-color: #fff;
}

:deep(.ant-upload.ant-upload-select-picture-card) {
  overflow: hidden;
  position: relative;
  &:hover {
    .shadow-bg {
      display: flex;
    }
    .delete-icon {
      display: block;
    }
  }
  .delete-icon {
    display: none;
    background: #3d7fff;
    color: white;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 32px;
    height: 32px;
    line-height: 44px;
    text-align: left;
    padding-left: 4px;
    font-size: 16px;
    border-radius: 50%;
    z-index: 2;
  }
  .shadow-bg {
    background-color: rgba(0, 0, 0, 0.25);
    display: none;
    flex-direction: column;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    .anticon-eye-o {
      color: white;
    }
  }
}
//禁用及上传   隐藏删除按钮
.disable :deep(.ant-upload.ant-upload-select-picture-card:hover) {
  .delete-icon {
    display: none;
  }
}
:deep(.ant-upload-disabled) {
  .delete-icon {
    display: none !important;
  }
}
//禁用字号color #4d4d4d
:deep(.ant-input[disabled]) {
  color: #4d4d4d;
}
</style>
